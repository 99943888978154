import { UrlSegment, Router, UrlSegmentGroup, UrlTree } from '@angular/router';

import { StoreService } from '@web/core/services';
import { eStoreActions } from '@web/enums';
import { Store } from '@web/core/models';
import { IBitfStoreEvent } from '../interfaces';

export function updateAppLayout(router, storeService: StoreService, mobilePrefix = 'm') {
  storeService.selectStore(eStoreActions.BREAKPOINT).subscribe((storeEvent: IBitfStoreEvent<Store>) => {
    const currentPath: string = router.location.path();

    const isInMobileLayout = currentPath.split('/')[1] === mobilePrefix;
    const showWebLayout = storeService.store.activeBreakpoints.isWebLayout;
    if (showWebLayout && isInMobileLayout) {
      // Redirect to desktop layout
      const path = currentPath.replace(`/${mobilePrefix}`, '');
      router.navigateByUrl(path);
    } else if (!showWebLayout && !isInMobileLayout) {
      // Redirect to mobile layout
      router.navigateByUrl(`${mobilePrefix}${currentPath}`);
    }
  });
}

export function initDynamicLayoutUrl(router: Router, storeService, mobilePrefix = 'm') {
  const originalCreateUrlTree = router.createUrlTree.bind(router);
  router.createUrlTree = (...params) => {
    const showWebLayout = storeService.store.activeBreakpoints.isWebLayout;
    const urlTree: UrlTree = originalCreateUrlTree(...params);
    const firstSegment: string =
      urlTree.root.children.primary && urlTree.root.children.primary.segments[0].path;

    if (showWebLayout && firstSegment === mobilePrefix) {
      urlTree.root.children.primary.segments = urlTree.root.segments.slice(1);
    } else if (!showWebLayout && firstSegment !== mobilePrefix) {
      if (!firstSegment) {
        const rootChildren = new UrlSegmentGroup([new UrlSegment(mobilePrefix, {})], {});
        rootChildren.parent = urlTree.root;
        urlTree.root.children['primary'] = rootChildren;
      } else {
        urlTree.root.children.primary.segments.unshift(new UrlSegment(mobilePrefix, {}));
      }
    }
    return urlTree;
  };

  updateAppLayout(router, storeService);
}
