import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { AuthService } from '@services';

import { bitfIsCallingApi } from '@bitf/utils/bitf-urls.utils';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
import { EBitfInterceptors } from '@enums';
@Injectable()
export class BitfOAuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(EBitfInterceptors.BITF_OAUTH_INTERCEPTOR)) {
      const newReq = req.clone({
        headers: req.headers.delete(EBitfInterceptors.BITF_OAUTH_INTERCEPTOR),
      });
      return next.handle(newReq);
    }

    // FIXME: this is custom must be fixed
    const isCallingLoginEndpoints = req.url.includes('/auth/security');

    if (
      bitfIsCallingApi(environment, req) &&
      !isCallingLoginEndpoints &&
      bitfGetProp(this.authService, 'authTokenMetaData', 'token')
    ) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${this.authService?.authTokenMetaData?.token}`),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
