export enum EBitfGraphQlQueryOperator {
  AND = '$and',
  OR = '$or',
  LIKE = '$like',
  NOT = '$not',
  GREATER = '$gt',
  GREATER_EQUAL = '$gte',
  LOWER = '$lt',
  LOWER_EQUAL = '$lte',
  EQUAL = 'IM_NOT_AN_OPERATOR',
}
