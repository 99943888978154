import { Subject } from 'rxjs';

import { IBitfUiMessages, IBitfBrowser } from '@interfaces';
import { detectBrowser } from '@bitf/utils/bitf-browser.utils';
import { IBitfStoreParts } from '../api-call-state/bitf-api-call-state.interfaces';

/**
 * The store model, used by Bitf core services
 */
export abstract class BitfStore {
  activeBreakpoints = {
    isXSmall: undefined,
    isSmall: undefined,
    isMedium: undefined,
    isLarge: undefined,
    isXLarge: undefined,
    isHandset: undefined,
    isTablet: undefined,
    isWeb: undefined,
    isHandsetPortrait: undefined,
    isTabletPortrait: undefined,
    isWebPortrait: undefined,
    isHandsetLandscape: undefined,
    isTabletLandscape: undefined,
    isWebLandscape: undefined,
    isHandsetLayout: undefined,
    isTabletLayout: undefined,
    isWebLayout: undefined,
  };
  // NOTE: will be true if the app is lounched in standalone mode from PWA
  isStandAlone = false;

  uiMessages$ = new Subject<IBitfUiMessages>();

  apiCallState: { [key: string]: IBitfStoreParts } = {};

  constructor(storeData: any = {}) {
    Object.assign(this, storeData);
  }

  get browser(): IBitfBrowser {
    return detectBrowser();
  }

  set browser(data: IBitfBrowser) {}

  toJSON() {
    const copy = Object.assign({}, this);
    delete copy.activeBreakpoints;
    delete copy.uiMessages$;

    return copy;
  }
}
