import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BitfDynamicLocaleService } from '../core/services/locale/bitf-dynamic-locale.service';

@Pipe({ name: 'bitfDate', pure: false })
export class BitfDatePipe implements PipeTransform {
  private lastLocale: string;
  private lastResult: string;
  private lastValue: any;
  private datePipe: DatePipe;

  constructor(private bitfDynamiclocaleService: BitfDynamicLocaleService) {
    this.datePipe = new DatePipe('en');
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): string {
    try {
      locale = locale || this.bitfDynamiclocaleService.locale.code;
      if (value !== this.lastValue || locale !== this.lastLocale) {
        this.lastLocale = locale;
        this.lastResult = this.datePipe.transform(value, format, timezone, locale);
        this.lastValue = value;
      }

      return this.lastResult;
    } catch (error) {
      console.error(error);
      return value;
    }
  }
}
