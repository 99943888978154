export const compareWith = (val1: any, val2: any) => {
  if (val1 && val1.id && val2 && val2.id) {
    return val1.id === val2.id;
  }
  if (val1 && val1.id && val2 && !val2.id) {
    return val1.id === val2;
  }
  if (val1 && !val1.id && val2 && val2.id) {
    return val1 === val2.id;
  }
  return val1 === val2;
};
