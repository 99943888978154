import { IBitfApiPagination, IBitfGraphQlRequest } from '@interfaces';
import { BitfGraphQlResponseMapper } from '../graph-ql-parser/bitf-graph-ql-response.mapper';

export class BitfPimcoreResponseMapper extends BitfGraphQlResponseMapper {
  constructor() {
    super();
  }

  // Query
  protected isResponseArray(response): boolean {
    return !!response.edges;
  }

  protected getContentAsArray<T>(response, model): T[] {
    return response.edges.map(edge => new model(edge.node));
  }

  protected calculatePagination(requestParams: IBitfGraphQlRequest, response: any): IBitfApiPagination {
    // NOTE: page starts from 1 to avoid mismatch between page and totalPages
    const size = requestParams.size;
    const page = requestParams.page;
    const totalItems = response.totalCount;
    const totalPages = Math.ceil(totalItems / size);
    const first = page === 1;
    const last = page === totalPages;
    let itemsInPage;
    if (last) {
      if (totalItems % size > 0) {
        itemsInPage = totalItems % size;
      } else {
        itemsInPage = size;
      }
    } else {
      if (size * page <= totalItems) {
        itemsInPage = size;
      } else {
        itemsInPage = 0;
      }
    }
    return {
      first,
      last,
      page,
      size,
      itemsInPage,
      totalItems,
      totalPages,
    };
  }

  // Mutation
  protected extractMutationResponseAndMetadata({ originalBody, responseEnveloped }) {
    responseEnveloped.success = originalBody?.success;
    responseEnveloped.message = originalBody?.message;
    return originalBody?.output;
  }
}
