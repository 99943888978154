import { OnInit, Input, OnDestroy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ApiCallStateService } from '@services';
import { EApiRequestPartKeys } from '@enums';
import { EBitfApiSortDirection } from '@bitf/enums';
import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { IBitfSortOption } from '@interfaces';

@Component({
  selector: 'bitf-sort',
  template: '',
})
export class BitfSortComponent implements OnInit, OnDestroy {
  @Input()
  apiCallStateName;

  @Input()
  apiRequestPartKey = EApiRequestPartKeys.SORTING;

  // Use for type dropdown
  @Input()
  options: IBitfSortOption[] = [];

  // Use for type button
  @Input()
  option: IBitfSortOption;

  EBitfApiSortDirection = EBitfApiSortDirection;

  formControl = new UntypedFormControl(null);
  private subscription: Subscription = new Subscription();
  private requestPart: BitfApiRequestPart;
  private paginationRequestPart: BitfApiRequestPart;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    this.requestPart = this.apiCallStateService.getRequestPart(this.apiCallStateName, this.apiRequestPartKey);

    this.paginationRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.PAGINATION
    );

    if (this.isDropDown()) {
      this.initDropdown();
      // NOTE: this refresh is needed in case some other part or controller will change this part data
      // (the value of the sorting)
      this.subscription = this.apiCallStateService.getStateStore$(this.apiCallStateName).subscribe(() => {
        this.initDropdown();
      });
    } else {
      // NOTE: we've to set only the property because the direction could being already set
      // by the apiCallState part defaultPartData or from user interaction.
      // We don't want to override that value
      this.requestPart.data.property = this.option.value.property;
      this.updateSortState();
      // NOTE: this refresh is needed in case some other part or controller will change this part data
      // (the value of the sorting), this is needed also to update the: this.option.value.direction
      this.subscription = this.apiCallStateService.getStateStore$(this.apiCallStateName).subscribe(() => {
        this.updateSortState();
      });
    }
  }

  // Dropdown
  onSelectionChange(event) {
    this.requestPart.data = event.value.property ? event.value : undefined;
    this.dispatchEvent();
  }

  // Button
  sort(option: IBitfSortOption) {
    switch (option.value.direction) {
      case EBitfApiSortDirection.ASC:
        this.requestPart.data.direction = EBitfApiSortDirection.DESC;
        break;
      case EBitfApiSortDirection.DESC:
        this.requestPart.data.direction = EBitfApiSortDirection.UNDEFINED;
        break;
      case EBitfApiSortDirection.UNDEFINED:
        this.requestPart.data.direction = EBitfApiSortDirection.ASC;
        break;
    }
    this.updateSortState();
    this.dispatchEvent();
  }

  isDropDown(): boolean {
    return this.options.length > 0;
  }

  private initDropdown() {
    const data = this.requestPart.data;
    if (data) {
      const optionFound = this.options.find(
        item => item.value.property === data.property && item.value.direction === data.direction
      );
      if (optionFound) {
        this.formControl.patchValue(optionFound.value);
      }
    }
  }

  private updateSortState() {
    // NOTE: we need to update just the direction value, the property stays the same
    this.option.value.direction = this.requestPart.data.direction || EBitfApiSortDirection.UNDEFINED;
  }

  private dispatchEvent() {
    this.apiCallStateService.setStore(() => {
      this.paginationRequestPart.reset();
    }, this.apiCallStateName);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
