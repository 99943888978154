import { Injectable } from '@angular/core';
import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class BitfMetadataService {
  titlePrefix = '';
  defaultTitle = '';
  titlePostfix = '';
  defaultDescription = '';

  constructor(private meta: Meta, private title: Title, private translateService: TranslateService) {}

  init() {
    this.loadTranslations();
  }

  setDefaultTitle() {
    if (this.defaultTitle) {
      this.setPageTitle(this.defaultTitle);
    }
  }

  setDefaultDescription() {
    if (this.defaultDescription) {
      this.updateMetaTags([{ name: 'description', content: this.defaultDescription }]);
    }
  }

  loadTranslations() {
    this.titlePrefix = this.translateService.instant('COMMON.META_TAGS.TITLE_PREFIX') || this.titlePrefix;
    this.defaultTitle = this.translateService.instant('COMMON.META_TAGS.DEFAULT_TITLE') || this.defaultTitle;
    this.titlePostfix = this.translateService.instant('COMMON.META_TAGS.TITLE_POSTFIX') || this.titlePostfix;
    this.defaultDescription =
      this.translateService.instant('COMMON.META_TAGS.DEFAULT_DESCRIPTION') || this.defaultDescription;
  }
  /**
   * Update the HTML Title
   *
   * @param string title
   * @return void
   */
  public async setPageTitle(titleToTranslate: string, extraTitle?: string) {
    const translatedTitlePrefix = this.titlePrefix && this.translateService.instant(this.titlePrefix);
    let translatedTitle = titleToTranslate && this.translateService.instant(titleToTranslate);
    if (!translatedTitle) {
      translatedTitle = this.defaultTitle;
    }
    const translatedTitlePostfix = this.titlePostfix && this.translateService.instant(this.titlePostfix);
    let fullTitle = translatedTitlePrefix;
    if (fullTitle) {
      fullTitle += ` ${translatedTitle}`;
    } else {
      fullTitle = translatedTitle;
    }
    if (fullTitle && extraTitle) {
      fullTitle += ` ${extraTitle}`;
    } else if (extraTitle) {
      fullTitle = extraTitle;
    }
    if (fullTitle && translatedTitlePostfix) {
      fullTitle += ` ${translatedTitlePostfix}`;
    }
    this.title.setTitle(fullTitle);
  }

  /**
   * Update the metaTags
   *
   * @param MetaDefinition[] metaTags
   * @return void
   */
  public updateMetaTags(metaTags: MetaDefinition[]) {
    metaTags.forEach(metaTag => {
      metaTag.content = this.translateService.instant(metaTag.content);
      this.meta.updateTag(metaTag);
    });
  }
}
