import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IBitfDialog, IBitfDialogService } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class DialogsService implements IBitfDialogService {
  public dialog: IBitfDialog;
  constructor(public matDialog: MatDialog) {
    this.dialog = matDialog;
  }
}
