import { Injectable } from '@angular/core';

import { IBitfToastData } from '@interfaces';
import { EBitfToastVerticalPosition, EBitfToastHorizontalPosition, EBitfUiMessageType } from '@enums';
import { BITF_CONFIGS } from '@configs';

@Injectable({
  providedIn: 'root',
})
export abstract class BitfToastMessagesService {
  protected defaultToastData: IBitfToastData = {
    type: EBitfUiMessageType.SUCCESS,
    title: '',
    verticalPosition: EBitfToastVerticalPosition.BOTTOM,
    horizontalPosition: EBitfToastHorizontalPosition.CENTER,
    duration: BITF_CONFIGS.toastMessage.durationMs,
    keyboardClose: true,
    closeButtonText: '',
    showCloseButton: false,
  };

  constructor() {}
}
