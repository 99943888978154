export enum EBitfPQueryComparators {
  EQUAL = 0,
  GREATER = 1,
  LOWER = 2,
  CONTAINS = 3,
  IN = 4,
  GREATEREQUAL = 5,
  LOWEREQUAL = 6,
  NOTIN = 7,
}
