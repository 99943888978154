export const bitfStripEmptyProps = (object: any) => {
  if (!object) {
    return {};
  }
  const newObj: any = {};
  for (const prop in object) {
    if (
      object.hasOwnProperty(prop) &&
      object[prop] !== null &&
      object[prop] !== undefined &&
      (!(object[prop] instanceof Array) || object[prop].length)
    ) {
      newObj[prop] = object[prop];
    }
  }
  return newObj;
};

export const bitfGetProp = (obj, ...p) => p.reduce((a, c) => Object(a)[c], obj);

export const bitfIsStrictObject = (object: any): boolean => {
  if (!object) {
    return false;
  }
  return object !== null && !Array.isArray(object) && typeof object === 'object';
};
