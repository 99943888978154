import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from '@common/core/services';
import { EBitfLoaderAction } from '@common/enums';
import { IBitfLoaderAction } from '@common/interfaces';

@Component({
  selector: 'bitf-mat-loader',
  templateUrl: './bitf-mat-loader.component.html',
  styleUrls: ['./bitf-mat-loader.component.scss'],
})
export class BitfMatLoaderComponent implements OnInit {
  @Input() diameter = 30;

  canShowLoader = false;
  loaderData: any;

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.onLoaderChange.subscribe((loaderAction: IBitfLoaderAction) => {
      switch (loaderAction.action) {
        case EBitfLoaderAction.SHOW:
          this.loaderData = loaderAction.data;
          this.show();
          this.loaderService.isLoading = true;
          break;
        case EBitfLoaderAction.HIDE:
          this.loaderData = undefined;
          this.hide();
          this.loaderService.isLoading = false;
          break;
      }
    });
  }

  show() {
    setTimeout(() => (this.canShowLoader = true));
  }

  hide() {
    setTimeout(() => (this.canShowLoader = false));
  }
}
