import { IBitfApiRequest, IBitfRequestMapper } from '@interfaces';
import { EBitfApiSortDirection } from '@enums';

import { BitfRestRequestMapper } from '@bitf/parsers/rest-parser/bitf-rest-request.mapper';
import { objectTypeCallProperty } from '@babel/types';

export abstract class BitfPRequestMapper extends BitfRestRequestMapper implements IBitfRequestMapper {
  constructor() {
    super();
  }

  map(requestParams: IBitfApiRequest) {
    const { embed, computedFields, page, size, paginationInfo, queryParams } = requestParams;
    const params: any = {};
    const requestMapped = super.map(requestParams);
    const { query, sorting, search } = requestParams;

    if (requestParams.method !== 'GET') {
      requestParams.body = requestParams.body || {};
    }

    if (embed && embed.length) {
      params.embed = embed.join(',');
    }

    // if (computedFields) {
    //   params.computedFields = computedFields.join(',');
    // }

    if (requestParams.method === 'GET') {
      if (page !== undefined) {
        params.pageNumber = page;
      }

      if (size !== undefined) {
        params.pageSize = size;
      }

      if (paginationInfo !== undefined) {
        params.paginationInfo = paginationInfo;
      }

      if (query) {
        params.filters = JSON.stringify(query);
      }
    } else if (requestParams.method === 'POST') {
      if (sorting && sorting.length) {
        requestParams.body.orderby = sorting
          .filter(itemFilter => Object.keys(itemFilter).length)
          .map(item => ({
            name: item.property,
            direction: item.direction === EBitfApiSortDirection.ASC ? 0 : 1,
          }));
      }

      if (page !== undefined) {
        requestParams.body.pageNumber = page;
      }

      if (size !== undefined) {
        requestParams.body.pageSize = size;
      }

      if (paginationInfo !== undefined) {
        requestParams.body.paginationInfo = paginationInfo;
      }

      if (query) {
        requestParams.body.filter = query;
      }

      if (search) {
        Object.assign(requestParams.body, search);
      }
    }

    if (queryParams) {
      Object.assign(params, queryParams);
    }

    requestMapped.params = requestMapped.params || {};
    if (Object.keys(params).length) {
      Object.assign(requestMapped.params, params);
    }

    return requestMapped;
  }
}
