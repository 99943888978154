import { UntypedFormControl } from '@angular/forms';

// https://github.com/angular/angular/blob/e96b3793852ebfd79a54a708363691b11818b4a0/packages/forms/src/validators.ts#L98
// NOTE changed last * into + to make an email like aaa@bbb not valid (it needs at least a "bbb.co")
export const bitfEmailRegex =
  // eslint-disable-next-line max-len
  /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

export function bitfValidateEmail(formControl: UntypedFormControl) {
  const reg = new RegExp(bitfEmailRegex);
  const value = formControl.value && formControl.value.trim ? formControl.value.trim() : formControl.value;
  return reg.test(value) ? false : { email: true };
}
