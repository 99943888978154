export enum EBitfParsers {
  BITF_APP_PARSER = 'appParser',
  BITF_REST_PARSER = 'restParser',
  BITF_STRAPI_PARSER = 'strapiParser',
  BITF_DEFAULT_PARSER = 'defaultParser',
  BITF_MOCK_DB_PARSER = 'mockDbParser',
  BITF_MONGO_PARSER = 'mongoParser',
  BITF_ODATA_PARSER = 'oDataParser',
  BITF_SQL_PARSER = 'sqlParser',
}
