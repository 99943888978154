import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import justClone from 'just-clone';

import { IBitfStoreEvent } from '@interfaces';
import { eStoreActions } from '@enums';
import { BitfStorageService } from '../storage/bitf-storage.service';

/**
 * Store service.
 * The store is a centralized application state
 */
export abstract class BitfStoreService<T> {
  protected _store: T;
  protected storeClass: new (data?: Partial<T>) => T;
  protected storage: BitfStorageService<T>;
  protected initialData: Partial<T>;
  public readonly store$ = new Subject<IBitfStoreEvent<T>>();

  // NOTE: inital data must be an object to be passed in the storeClass constructor not a storeClass instance
  // this is to avoid to create something like new storeClass(justClone(StoreClassInstance)); which will
  // lead to problems

  /**
   * @param initialData for the application state
   * @param storeClass class for the Store model
   * @param storage Optional: you can provice a storage service to persist the store state
   */
  constructor({
    initialData,
    storeClass,
    storage,
  }: {
    initialData?: Partial<T>;
    storeClass: new (data?: Partial<T>) => T;
    storage?: BitfStorageService<T>;
  }) {
    if (!storeClass) {
      throw new Error('storageClass is undefined');
    }
    this.storeClass = storeClass;
    this.initialData = initialData || ({} as T);
    this.storage = storage;
    if (storage) {
      this._store = storage.data;
    } else {
      this._store = new storeClass(justClone(this.initialData));
    }
  }

  /**
   * Returns the Store
   */
  get store(): T {
    return this._store;
  }

  /**
   * Method to reset the store state
   */
  resetStore() {
    // FIME: this will break the apiCallStates since in the BitfRequestPart.init() there we assign the
    // store in the constructor and then it change
    if (this.storage) {
      this.storage.resetStorage();
      this._store = this.storage.data;
    } else {
      this._store = new this.storeClass(justClone(this.initialData));
    }
    window['store'] = this._store;
    this.store$.next({ action: eStoreActions.RESET, store: this.store } as IBitfStoreEvent<T>);
  }

  // NOTE: this '= eStoreActions.CREATE' will infer the action eStoreActions also if it is a constant
  /**
   * Method to update the store. It is important to use this method to update the store, otherwise
   * listeners will not be notified of the changes in the store state.
   * Eg.
   * storeService.setStore((store)=>store.appTitle = 'A title', eStoreActions.TITLE_CHANGED)
   *
   * @param callback used to update the store
   * @param action optional, the action to notify only the listeners interested in this update
   */
  setStore(fn: (store: T) => void, action: string = eStoreActions.UPDATE) {
    fn(this.store);
    if (this.storage) {
      this.storage.setData(this.store);
    }
    window['store'] = this._store;
    this.store$.next({ action, store: this.store } as IBitfStoreEvent<T>);
  }

  /**
   * Utility function to listen only for store updates of this action
   *
   * @param action The action to listen to
   */
  selectStore(action: string | string[]) {
    if (Array.isArray(action)) {
      return this.store$.pipe(filter(item => action.includes(item.action)));
    }
    return this.store$.pipe(filter(item => item.action === action));
  }
}
