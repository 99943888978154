import {
  IBitfApiUiMessage,
  IBitfGraphQlEnvelopeMapper,
  IBitfGraphQlRequest,
  IBitfGraphQlResponse,
} from '@interfaces';
export class BitfGraphQlEnvelopeMapper implements IBitfGraphQlEnvelopeMapper {
  constructor() {}
  // NOTE: this is not running at HTTP level as interceptor so we have few parameters mapped here
  map(requestParams: IBitfGraphQlRequest, graphQlResponse: any): IBitfGraphQlResponse<any> {
    const envelopedResponse = {
      content: undefined,
      pagination: undefined,
      sorting: undefined,
      metadata: { uiMessages: [] },
      networkStatus: graphQlResponse.networkStatus,
      // headers: undefined,
      originalBody: graphQlResponse.data,
      bulkResponse: {},
    } as IBitfGraphQlResponse<any>;

    // NOTE: Search for errors
    const { modelMapper } = requestParams;
    const message = graphQlResponse?.data && graphQlResponse.data[modelMapper]?.message;
    // NOTE: check if the mutation return success: false
    const success = graphQlResponse?.data && graphQlResponse.data[modelMapper]?.success;
    if (success === false) {
      envelopedResponse.metadata.uiMessages.push({
        title: 'Error',
        message: message || 'Error running the query',
        type: 'ERROR',
        target: 'TOAST',
      } as IBitfApiUiMessage);
    } else {
      // NOTE: check if there is a message. This should be an info, the error case should be handled above
      if (message) {
        envelopedResponse.metadata.uiMessages.push({
          title: '',
          message,
          type: 'INFO',
          target: 'TOAST',
        } as IBitfApiUiMessage);
      }
    }

    // NOTE: Search for query errors
    const { errors } = graphQlResponse;
    if (errors?.length) {
      const errorsForLog = errors.reduce((acc, error) => acc + error.message, '');
      envelopedResponse.metadata.uiMessages.push({
        title: 'Error',
        message: errorsForLog,
        type: 'ERROR',
        target: 'TOAST',
      } as IBitfApiUiMessage);
    }

    return envelopedResponse;
  }
}
