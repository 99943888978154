import { Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, Subject, fromEvent, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

import { BITF_CONFIGS } from '@configs';
import { environment } from '@env/environment';
import { EBitfOnlineStates, EBitfCloseEventStatus, EBitfUiMessageType } from '@enums';
import { IBitfCloseEvent } from '@interfaces';
import { StoreService, DialogsService, ToastMessagesService } from '@services';
import { CONSTANTS } from '@constants';
import { TranslateService } from '@ngx-translate/core';

// PWA REF:
// https://developers.google.com/web/updates/2018/06/a2hs-updates
// PWA debugger infos
// https://developers.google.com/web/ilt/pwa/tools-for-pwa-developers

@Injectable({
  providedIn: 'root',
})
export class BitfPwaService {
  onlineStatus: EBitfOnlineStates = EBitfOnlineStates.ONLINE;
  swUpdateNotified = false;
  onlineStatus$: Subject<EBitfOnlineStates> = new Subject<EBitfOnlineStates>();
  swUpdate$: Subject<any> = new Subject<any>();
  onAppInstalled$: Observable<Event>;
  beforeInstallPrompt$ = new BehaviorSubject<Event>(undefined);
  beforeInstallPromptEvent: any;

  constructor(
    public swUpdate: SwUpdate,
    private storeService: StoreService,
    private cookieService: CookieService,
    private toastMessageService: ToastMessagesService,
    private translateService: TranslateService,
    public dialogsService: DialogsService
  ) {}

  init() {
    if (!environment.registerServiceWorker) {
      return;
    }
    this.detectStandaloneMode();
    this.initOnAppInstalled();
  }

  /**
   * Inizialize the manifest tag (creating a new one if don't exist)
   * and set the href attribute created from the param
   *
   * @param string manifest path of the manifest file to load
   */
  initManifest(manifest = '/manifest.json'): void {
    // const blob = new Blob([JSON.stringify(manifest)], { type: 'application/json' });
    // const manifestURL = URL.createObjectURL(blob);
    const manifestTag = document.querySelector('head > link[rel="manifest"]');
    if (manifestTag) {
      manifestTag.setAttribute('href', manifest);
    }
  }

  // Install app management
  initBeforeInstallPrompt() {
    if (!environment.registerServiceWorker) {
      return;
    }
    fromEvent(window, 'beforeinstallprompt')
      .pipe(
        tap(event => {
          event.preventDefault();
          // NOTE: we've to run this here because when the user installs the app in desktop
          // the app is moved in a detached window without bing reinstalled without running init()
          // again. The beforeinstallprompt run again without beforeInstallPromptNotAccepted set
          // so to avoid to reopen the install prompt we've to check isStandAlone flag
          this.detectStandaloneMode();
          const shoudEmitEvent =
            !this.storeService.store.isStandAlone &&
            !this.cookieService.get(`${environment.appName}-beforeInstallPromptNotAccepted`);
          if (shoudEmitEvent) {
            this.beforeInstallPrompt$.next(event);
            this.beforeInstallPromptEvent = event;
          }
        })
      )
      .subscribe();
  }

  checkForBeforeInstallPromptEvent() {
    if (!environment.registerServiceWorker) {
      return;
    }
    this.beforeInstallPrompt$.subscribe(event => {
      if (event) {
        const expireDate = new Date(
          Date.now() + BITF_CONFIGS.pwa.serviceWorker.askAgainAfterNDays * 24 * 60 * 60 * 1000
        );
        this.showDefaultInstallPromptDialog(event, expireDate);
      }
    });
  }

  showDefaultInstallPromptDialog(event, askAfterDate: Date) {
    if (!event) {
      return;
    }
    const dialogRef = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, {
      width: 'auto',
      maxWidth: '90%',
      disableClose: true,
      data: {
        title: bitfToTranslate('BITF.LABEL.INFO'),
        message: bitfToTranslate('BITF.DIALOG.PWA.INFORM_ABOUT_INSTALL'),
        okText: bitfToTranslate('BITF.LABEL.OK'),
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      event.prompt();
      // Wait for the user to respond to the native prompt
      event.userChoice.then(choiceResult => {
        if (choiceResult.outcome !== 'accepted') {
          // NOTE: in case the user clicks no we'll defer the dialog to askAfterDate date
          this.cookieService.set(
            `${environment.appName}-beforeInstallPromptNotAccepted`,
            'not-accepted',
            askAfterDate
          );

          // NOTE we've to send a next with undefined also if the user accept otherwise we'll prompt again
          // beforeinstallprompt emits multiple times, it seems a bug
          this.beforeInstallPrompt$.next(undefined);
          this.beforeInstallPromptEvent = undefined;

          return;
        }
      });
    });
  }

  // Push updates management
  initSwUpdate() {
    if (!environment.registerServiceWorker) {
      return;
    }

    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(updateDetails => {
        this.swUpdate.activateUpdate().then(() => {
          if (!this.swUpdateNotified) {
            this.swUpdate$.next(updateDetails);
          }
          this.swUpdateNotified = true;
        });
      });

    this.swUpdate.checkForUpdate();
    setInterval(() => {
      this.swUpdate.checkForUpdate();
    }, BITF_CONFIGS.pwa.serviceWorker.checkUpdateInterval);
  }

  handleSwUpdate() {
    this.swUpdate$.subscribe(updateDetails => {
      this.showDefaultSwUpdateDialog(updateDetails);
    });
  }

  showDefaultSwUpdateDialog(updateDetails) {
    const dialogOptions = {
      width: 'auto',
      maxWidth: '90%',
      disableClose: true,
      data: {
        title: bitfToTranslate('BITF.LABEL.INFO'),
        message: bitfToTranslate('BITF.APP_UPDATE.NEW_APP_AVAILABLE_PROMPT'),
        cancelText: bitfToTranslate('BITF.LABEL.NO'),
        okText: bitfToTranslate('BITF.LABEL.YES'),
      },
    };
    // We've to force PWA on safari to refresh because id the user click no, next time he'll open
    // the app, the app will not refresh, so the user is stuck to the old version
    // NOTE: it seems to work now TOBE REMOVED
    // if (this.storeService.store.browser.isSafariMobile && this.storeService.store.isStandAlone) {
    //   Object.assign(dialogOptions.data, {
    //     message: bitfToTranslate('BITF.APP_UPDATE.FORCE_NEW_APP_UPDATE_PROMPT'),
    //     okText: bitfToTranslate('BITF.LABEL.OK'),
    //     cancelText: undefined,
    //   });
    // }
    const dialogRef = this.dialogsService.dialog.open(CONSTANTS.okCancelDialogComponent, dialogOptions);
    dialogRef.afterClosed().subscribe((result: IBitfCloseEvent<void>) => {
      if (result.status === EBitfCloseEventStatus.OK) {
        window.location.reload();
      }
    });
  }

  initOnlineChecker() {
    this.handleStateChange();
    window.addEventListener('online', this.handleStateChange.bind(this));
    window.addEventListener('offline', this.handleStateChange.bind(this));
  }

  showDefaultOnlineStateChangeToast() {
    this.onlineStatus$.subscribe(event => {
      if (event === EBitfOnlineStates.OFFLINE) {
        this.toastMessageService.show({
          type: EBitfUiMessageType.WARNING,
          title: this.translateService.instant('CONNECTION.TOAST.CONNECTION'),
          message: this.translateService.instant('CONNECTION.TOAST.OFFLINE'),
        });
      } else {
        this.toastMessageService.show({
          type: EBitfUiMessageType.WARNING,
          title: this.translateService.instant('CONNECTION.TOAST.CONNECTION'),
          message: this.translateService.instant('CONNECTION.TOAST.ONLINE'),
        });
      }
    });
  }

  private handleStateChange() {
    if (navigator.onLine) {
      this.switchStatus(EBitfOnlineStates.ONLINE);
    } else {
      this.switchStatus(EBitfOnlineStates.OFFLINE);
    }
  }

  private switchStatus(currentStatus: EBitfOnlineStates) {
    if (this.onlineStatus !== currentStatus) {
      this.onlineStatus = currentStatus;
      this.onlineStatus$.next(this.onlineStatus);
    }
  }

  private initOnAppInstalled() {
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/onappinstalled
    this.onAppInstalled$ = fromEvent(window, 'onappinstalled');
  }

  private detectStandaloneMode() {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] === true) {
      this.storeService.store.isStandAlone = true;
    }
  }
}
